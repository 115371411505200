input[type="file"] {
	background-color: red;
	height: 300px;
}

input[type="file"]:hover {
	cursor: pointer;
}

img {
	width: 100%;
}

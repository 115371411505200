.ql-editor * {
	font-family: "Poppins", sans-serif !important;
	/* color: #2b2c31 !important; */
	color: #2b2c31;
	z-index: 999;
}

.ql-editor {
	width: 100%;
	min-height: 150px;
	z-index: 999;
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	/* padding: 9px; */
	/* height: 100%; */
	border-width: 0.1px;
}

.ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 80%;
	object-fit: contain;
	color: #2b2c31 !important;
}

.cursor {
	cursor: pointer;
	color: #fff !important;
}

.ql-snow {
	font-family: "Poppins", sans-serif !important;
	color: #2b2c31 !important;
}

.ql-tooltip {
	left: 10px !important;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	overflow: hidden;

	transform: scale(1);
	transform-origin: 0 0;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*:focus {
	outline: none;
}

.pdf-page-break {
	page-break-after: always;
	display: block;
	clear: both;
}

@media print {
	.pagebreak {
		page-break-before: always;
	}
}

/* @font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Book.otf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Bold.otf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Bold.otf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
} */

img.responsive {
	width: 100%; /* This sets the width of the image to the width of its container */
	height: auto; /* This allows the height to adjust proportionally to the width */
}
